
import { GET_USER, GET_USER_WORKSPACES } from '@/store/types';
import WorkspaceList from '@/components/workspaces/WorkspaceList.vue';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { Workspace } from '@/store/workspace/models';
import AddWorkspace from '@/components/workspaces/AddWorkspace.vue';

const workspace: string = 'workspace';
const user: string = 'user';

@Component({
  components: {
    WorkspaceList,
    AddWorkspace,
  },
})
export default class WorkspaceListContainer extends Vue {
  // Getters
  @Getter(GET_USER, { namespace: user }) getUser: any;

  @Getter(GET_USER_WORKSPACES, { namespace: workspace }) workspaces: any;

  // Data
  private workspaceSearchTerm = '';

  private addDialog: boolean = false;

  // Computed
  get filteredWorkspaces(): Workspace[] {
    // return all workspaces if search term is empty
    if (!this.workspaceSearchTerm) {
      return this.workspaces;
    }

    // return filtered workspaces
    return this.workspaces.filter((w: Workspace) => w.name.includes(this.workspaceSearchTerm));
  }
}
