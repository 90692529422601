
import { Component, Vue, Prop } from 'vue-property-decorator';
import { required } from 'vuelidate/lib/validators';
import { Validations } from 'vuelidate-property-decorators';
import { Getter, Action } from 'vuex-class';
import * as types from '@/store/types';

const workspaceNamespace: string = 'workspace';
const userNamespace: string = 'user';

@Component
export default class AddWorkspace extends Vue {
  @Prop({ default: false }) dialog!: boolean;

  @Getter(types.GET_USER, { namespace: userNamespace }) getUser: any;

  @Getter(types.GET_USER_WORKSPACES, { namespace: workspaceNamespace }) getWorkspaces: any;

  @Action(types.ADD_WORKSPACE, { namespace: workspaceNamespace }) addWorkspace: any;

  private workspaceName: string = '';

  close() {
    this.$emit('close');
  }

  @Validations()
  validations = {
    workspaceName: {
      required,
      isNameUnique() {
        return !this.isNameUnique;
      },
    },
  };

  get isNameUnique(): boolean {
    const workspace = this.getWorkspaces.find((w: any) => w.owner.id === this.getUser.id
      && this.workspaceName.trim().toLowerCase() === w.name.toLowerCase());

    return !!workspace;
  }

  get workspaceNameErrors() {
    const errors: any[] = [];
    if (!this.$v.workspaceName.$dirty) return errors;
    if (!this.$v.workspaceName.isNameUnique) errors.push(this.$t('workspace name already in use'));
    if (!this.$v.workspaceName.required) errors.push(this.$t('required_field'));
    return errors;
  }

  get isAllowToSubmit(): boolean {
    return this.$v.$invalid || !this.$v.workspaceName.$dirty;
  }

  async submit() {
    this.$v.$touch();
    if (this.isAllowToSubmit) return;
    const owner = {
      id: this.getUser.id,
      username: this.getUser.username,
      email: this.getUser.email,
    };
    const res = await this.addWorkspace({ name: this.workspaceName.trim(), owner });
    if (res && res === 200) {
      this.close();
    }
    this.workspaceName = '';
  }
}
