
import { getWorkspaceIcon } from '@/scripts/bricks/getIcon';
import { Workspace } from '@/store/workspace/models';
import { Component, Prop, Vue } from 'vue-property-decorator';
import Avatar from '@/components/user/Avatar.vue';
import { Getter } from 'vuex-class';
import { GET_USER } from '@/store/types';

const user: string = 'user';

@Component({
  components: {
    Avatar,
  },
})
export default class WorkspaceList extends Vue {
  // Prop
  @Prop() workspaces!: Workspace[];

  // Getters
  @Getter(GET_USER, { namespace: user }) getUser: any;

  // Methods

  /**
   * Returns the default icon or workspace
   * icon with proper BASE64 prefix
   *
   * @param workspaceIcon
   */
  getIcon = (workspaceIcon: string = '') => getWorkspaceIcon(workspaceIcon);

  openFlows(id: string) {
    this.$router.push(`/flows/${id}`);
  }

  private truncated = (str: string) => (str.length > 40 ? str.substring(0, 40).concat('...') : str);
}
